
import Vue from 'vue';
import { Person, CreateClientInvitationRequest, SnackbarInfo, AclExtract } from "@/types/responses"
import { LinkOnboardingEnum } from "@/types/enums"

export default Vue.extend({
	name: "InvitationLinkDialog",
	props: {
		invitationLink: {
			type: Boolean,
		}
	},
    mounted() {
		this.getPersons()
	},
	data(): {
		snackBar: boolean
		snackBarText: string
		loading: boolean
        loadingAction: boolean
		timeLeft: number
        readMore: boolean

        onboardingenm: typeof LinkOnboardingEnum
        checkbox: boolean
		selectedPerson: null | string
        newName: string
        selectedOnboardingKind: null | string
        onboardingKinds: string[]
        rules: unknown
	} {
		return {
			snackBar: false,
			snackBarText: "",
			loading: false,
            loadingAction: false,
			timeLeft: 2,
            readMore: false,

            onboardingenm: LinkOnboardingEnum,
            checkbox: false,
			selectedPerson: null,
            newName: "",
            selectedOnboardingKind: null,
            onboardingKinds: ["Company", "Person"],
            rules: {
				required: (value: string) => !!value || 'This field cannot be empty',
				counter: (value: string) => value.length <= 60 || 'Max 60 characters'
			}
		}
	},
    computed: {
		usersComplete(): Person[] {
			return this.$store.state.context.assignableLinkOwners
		},
        personsComplete(): Array<{ text: string, value: null|string}> {
			const arr: Array<{ text: string, value: null|string}> = []
			arr.push({ text: "Select a user...", value: null})
			// eslint-disable-next-line
			const persons = this.$vStore.state.context.assignableLinkOwners
			.sort(function(a, b) {
				const obj1 = a['id'].toUpperCase()
				const obj2 = b['id'].toUpperCase()
				if (obj1 < obj2) return -1
				if (obj1 > obj2) return 1
				return 0
			})
			.map(person => {
				return { text: person.displayName, value: person.id}
			})

			const selectList = [...arr,...persons]
			return selectList
		},
        getOnboardingAcl(): AclExtract {
            return this.$store.state.acl[this.onboardingenm.LinkOnboarding];
        },
		delegateAcl(): boolean {
			return this.getOnboardingAcl && this.getOnboardingAcl.ui.includes(this.onboardingenm.OnboardingOwnerAssign)
		}
	},
	methods: {
        disableCreateButton(): boolean {
			if(this.loadingAction) return true
			if(this.newName.length < 1) return true
            if(this.selectedPerson == null) return true
            if(this.selectedOnboardingKind == null) return true

			return false
		},
        setDefaultManualApproval(): void {
            if (this.selectedOnboardingKind === "Person"){
                this.checkbox = false
            }else {
                this.checkbox = true
            }
        },
		async countDownTimer(): Promise<void> {
			this.timeLeft = 2
			return new Promise((resolve) => {
				const downloadTimer = setInterval(() => {
					this.timeLeft--
					if (this.timeLeft <= 0) {
						clearInterval(downloadTimer)
						resolve()
					}
				}, 1000)
			})
		},
        async getPersons(): Promise<void> {
			if (this.loading) return
			this.loading = true
			try {
				await this.$vStore.dispatch("context/updateLinkOnboardingUsers")
				this.loading = false
			} catch (e) {
				console.warn("Could not find any persons for this customer", e)
			}
		},
		cancel(): void {
			if(!this.loadingAction){
				this.$emit("close", null)
			}
		},

        async createInvitationLink(name: string, personId: string, manualApproval: boolean, onboardingKind: string): Promise<void> {
			this.loadingAction = true
            let snackbarInfo: SnackbarInfo;

			try {
                const createClientInvitationRequest: CreateClientInvitationRequest = {
                    name: name,
                    invitingPersonId: personId,
                    manualApproval: manualApproval,
                    onboardingKind: onboardingKind
                }

				console.log("CREATED INVITATION: ", createClientInvitationRequest)
				await this.$vStore.dispatch("context/createClientInvitation", createClientInvitationRequest)
				await this.countDownTimer()
                snackbarInfo = {
                    success: true,
                    msg: "Successfully created new registration link!",
                    e: null
                }
                this.$emit('showSnackbarInfo', true, 
                    snackbarInfo, true, 1)
				this.$emit('silentRefresh')
                this.$emit('silentRefreshClientOrganizations')

                this.loadingAction = false
                this.cancel()
			} catch (e) {
				snackbarInfo = {
                    success: false,
                    msg: "Could not create new registration link.",
                    e: e
                }
                this.$emit('showSnackbarInfo', false, 
                    snackbarInfo, false, 4)

                this.loadingAction = false
			}

		},

	},
})
